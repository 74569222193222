<template>
  <div>
    <!-- 레이블 -->
    <div class="sub body3-medium margin-bottom-12" v-if="label">{{ label }}</div>

    <div class="grid-box" style="margin-top:12px">
      <div v-for="(item, idx) in options"
           :key="`radio-button-${idx}`"
           class="flex-between full-width"
           :style="labelWrapStyle(item,idx)"
           @click.stop.prevent="selectRadioButton(item)">

        <div class="flex full-width">
          <div v-if="align==='left'"
               class="outer" :style="outerStyle(item)">
            <div v-if="item.value===selectedValue"
                 :style="innerStyle(item)"
                 class="inner flex-center">
            </div>
          </div>

          <div class="radio-button-text position-relative" :style="itemLabelStyle(item)"
               @mouseover="setHover(true, item)"
               @mouseleave="setHover(false, item)">{{ item.label }}</div>

          <div v-if="align==='right'"
               class="outer" :style="outerStyle(item)">
            <div v-if="item.value===selectedValue"
                 class="inner flex-center" :style="innerStyle(item)">
            </div>
          </div>
        </div>

        <i v-if="align==='check' && item.value===selectedValue" class="material-icons size-14 weight-700">check</i>
      </div>
    </div>
    <c-input-etc :value="selectedValue" :etc="etc" @update:etc="res => $emit('update:etc', res)"></c-input-etc>
  </div>
</template>

<script>
  import CInputEtc from "./CInputEtc";

  export default {
    name: 'c-input-radiobuttonGroup',
    components: {CInputEtc},
    props: {
      options: {
        type: Array,
        required: true
      },
      value: {
        type: [String, Number, Boolean],
        required: false
      },
      label: {
        type: String,
        required: false
      },
      etc: {
        type: String,
        required: false
      },
      align: {
        type: String,
        required: false,
        default: 'left'
      }
    },

    data() {
      return {
        currentIdx: 0,
        hover: false,
        selectedValue: '',
        hoverText: false,
        disabledState: ''
      }
    },

    created() {
      if (this.options) {
        this.disabledState = this.options.every(item => {
          return item.disabled===undefined || item.disabled===false || item.disabled==='';
        })
      }
      else this.disabledState = true;


      if(this.disabledState===false) {
        this.options.forEach(item => {
          if(item.disabled) {

            for(let i=0;this.options.length;i++) {
              if ((this.options[i].disabled===undefined || this.options[i].disabled===false) && (this.options[i].value !==undefined || this.options[i].value!=='') && this.selectedValue==='') {
                this.selectedValue = this.options[i].value;
                this.$emit('update:value', this.selectedValue);
                this.$emit('update:selectedValue', this.findItem(this.options, 'value', this.selectedValue).label);
                break;
              }
            }
          }
        })
      }

      else {
        if(this.value) {
          this.selectedValue = this.value;
          this.$emit('update:value', this.selectedValue)
        }
      }
    },
    watch: {
      options(n) {
        if(this.value.length===0) {
          this.selectedValue = n[0].value;
        }
      },
      value(n) {
        if(n || n===0 || n===false) {
          this.selectedValue = n;
        }
      }
    },
    methods: {
      outerStyle(item) {
        let deco = {
          width: '16px',
          height: '16px',
          margin: '4px 8px 0 0',
          border: '1px solid #dddddd',
          cursor: 'pointer'
        };
        if(item.disabled) {
          deco.cursor = 'default'
        }
        if(item.value === this.selectedValue) {
          deco.border = '1px solid #FF6600';
        }
        return deco;
      },

      innerStyle(item) {
        let deco = {
          width: '8px',
          height: '8px',
          backgroundColor: '#FF6600',
          cursor: 'pointer'
        };
        if(item.disabled) {
          deco.cursor = 'default'
        }
        return deco;
      },
      setHover(val, item) {
        this.hover = val;
        if(this.hover) {
          if(item.disabled && item.disabled_action==='hover') {
            this.hoverText = true;
          }
        }
        else {
          this.hoverText = false;
        }
      },
      labelWrapStyle(item) {
        let deco = {
          width: '100%',
          paddingBottom: '12px'
        };
        if(item.id===this.options[this.options.length-1].id) {
          deco.paddingRight = '0';
        }
        return deco;
      },
      itemLabelStyle(item) {
        let deco = {
          fontSize: '15px',
          flex: 1,
          color: '#242428'
        };
        if(item.disabled) {
          deco.color = '#828282';
          deco.cursor = 'default';
        }
        return deco;
      },
      selectRadioButton(item) {
        if(!item.disabled) {
          this.selectedValue = item.value;
          this.$emit('update:value', item.value);
          this.$emit('update:selectedValue', item.label);
        }
      },
    }
  }
</script>

<style lang="stylus" type="stylus" scoped>
  .grid-box
  .c-input-radiobutton-group-container
    display flex
    flex-wrap wrap

  .c-input-radiobutton-group-wrap
    display flex
    align-items center

  .radio-button
  .radio-button-text
    cursor pointer

  .outer
    border-radius 50%
    display flex
    align-items center
    justify-content center
    width 18px
    height 18px

  .inner
    border-radius 50%
    width 10px
    height 10px



  .hover-text-style
    left 0
    top 28px
    padding 2px 12px
    white-space pre
    border-radius 8px

</style>
