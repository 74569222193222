<template>
  <div>
    <!-- 레이블 -->
    <div class="sub body3-medium margin-bottom-12" v-if="label">{{ label }}</div>

    <div class="grid-box">
      <div class="unselect" :style="[gutterStyle(idx)]" v-for="(item, idx) in options" :key="`check-box-${idx}`">
        <div class="flex-center" @click="toggleCheck(item)" :style="btnStyle(item)">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CInputCheckboxGroup',
  props: {
    options: {
      type: Array,
      required: true,
    },
    value: {
      type: [Array, String],
      required: false,
    },
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      align: 'left',
      selectedValue: '',
    };
  },
  created() {
    if (this.value === undefined || this.value === '') this.selectedValue = '';
    else this.selectedValue = this.value;
  },
  watch: {
    value() {
      if (this.value === undefined) this.selectedValue = '';
      else this.selectedValue = this.value;
    },
    selectedValue(n) {
      this.$emit('update:value', n);
    },
  },
  methods: {
    gutterStyle(idx) {
      let deco = {
        width: '50%',
        padding: '4px',
      };
      let col = 6;
      let num = 12 / col;
      if (idx % num === 0) deco.paddingLeft = 0;
      // 열 첫번째
      else if (idx % num === num - 1) deco.paddingRight = 0; // 열 마지막
      return deco;
    },
    toggleCheck(item) {
      if (!this.selectedValue.includes(item.value)) {
        this.selectedValue = item.value; // 다른 모든 선택을 제거하고 현재 아이템만 선택
      }
      this.$emit('changed');
    },
    isChecked(item) {
      return this.selectedValue.includes(item.value);
    },
    btnStyle(item) {
      let deco = {
        borderRadius: '8px',
        border: '1px solid #dddddd',
        color: '#333333',
        backgroundColor: 'white',
        height: '48px',
        textAlign: 'center',
        padding: '0 12px',
      };
      if (this.selectedValue.indexOf(item.value) > -1) {
        deco.color = '#ff6600';
        deco.backgroundColor = '#FFF0E5';
        deco.border = '1px solid #FF6600';
      }

      return deco;
    },
  },
};
</script>

<style lang="stylus" type="stylus" scoped>
.grid-box
  display flex
  flex-wrap wrap
.check-box
  .check-box-text
    cursor pointer

  .check-box-label
    cursor pointer

  .check-align
    margin-right 10px
</style>
